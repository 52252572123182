<template>
  <div class="login-auth-own">
    <img id="logo" src="@/assets/logo.png" />
    <div class="main">
      <div class="introduction">
        <p class="introduction_main">EduSoho销客助手</p>
        <p class="introduction_sub">—— 专注教育的SCRM</p>
      </div>
      <div class="iframe_container">
        <div v-if="loadCount < 1" class="loading-box">
          <LoadingOutlined />
          <span>加载中...</span>
        </div>
        <a-button class=" modal-btn" type="link" @click="openCodeModal" v-else>遇到问题？联系客服解答</a-button>
        <div class="iframe-box">
          <iframe ref="iframe" sandbox="allow-scripts allow-top-navigation allow-same-origin" height="438px" width="400px" name="iframe"
            target="iframe" :src="redirectBaseUrl" style="
    border: none;" @load="load"></iframe>
        </div>

      </div>

    </div>
    <div class="footer">
      <img src="@/assets/square-left.png" alt="">
      <span class="footer_description">EduSoho， 深耕在线教育9年，全球500强企业在线教育解决方案提供商</span>
      <img src="@/assets/square-right.png" alt="">
    </div>
    <a-modal class="code-modal" v-model:visible="codeVisible" title="添加专属客服" :footer="null" width="400px" centered>
      <p>一对一为您答疑解惑</p>
      <img class="code-modal__img" src="@/assets/EduSohoCustomerServiceCode.png" />
      <p class="color-primary">立即扫码添加客服</p>
    </a-modal>
  </div>
</template>


<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { apiStore } from "@/shared/service/api-client";

export default {
  components: {
    LoadingOutlined,
  },
  data() {
    return {
      redirectBaseUrl: "",
      codeVisible: false,
      loadCount: 0,
    };
  },
  created: function () {
    this.getBaseUrl();
  },
  mounted() {},
  methods: {
    async getBaseUrl() {
      this.redirectBaseUrl =
        "https://" +
        window.location.host +
        process.env.BASE_URL +
        "work_wechat_auth";
    },
    openCodeModal() {
      this.codeVisible = true;
    },
    load() {
      if (this.loadCount === 0) {
        apiStore.clearAuth();
      }
      this.loadCount += 1;
    },
  },
  computed: {},
};
</script>
