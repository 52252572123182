import {apiClient as client} from '@/shared/service/api-client';

export default {
    async login(params) {
        return await client.post('/login', params);
    },
    async getWechatQrLoginUrl(params) {
        return await client.get('/login/workwechartQrLoginUrl', {params: params});
    },
    async wechatAuthLogin(params) {
        return await client.get('/login/workwechartQrLogin', {params: params});
    },
    async wechatAuthLoginTicket(params) {
      return await client.get('/login/getWorkWechatQrLoginTicket', {params: params});
    },
    async workWechatQrLoginCheck(params) {
      return await client.get('/login/workWechatQrLoginCheck', {params: params});
    },
}
