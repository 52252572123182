<template>
  <router-view />
</template>

<script>
import loginApi from "@/service/api/login";
import { apiStore } from "@/shared/service/api-client";

export default {
  data() {
    return {
      error: "",
      loginInfo: {
        authCode: this.$route.query.auth_code,
        unionId: this.$route.query.unionId,
      },
    };
  },
  created: function () {
    apiStore.clearAuth();
    this.wechatAuthLogin();
  },
  methods: {
    wechatAuthLogin: async function () {
      if (
        this.loginInfo.authCode === "" ||
        typeof this.loginInfo.authCode === "undefined"
      ) {
        try {
          const loginUrl = await loginApi.getWechatQrLoginUrl();
          window.location.href = loginUrl;
        } catch (err) {
          this.$router.go(0);
        }
        return;
      }

      try {
        const logined = await loginApi.wechatAuthLogin(this.loginInfo);
        apiStore.setAuth(logined.token, logined.user);
      } catch (err) {
        this.error = err.message;
        const errCodeList = [
          "40390006",
          "40390007",
          "40390008",
          "40390004",
          "40390009",
        ];
        if (errCodeList.includes(err.code)) {
          this.$router.push({
            path: "/work_wechat_auth_err",
            query: { errcode: Number(err.code) },
          });
          return;
        }

        return;
      }
      await this.$router.push({ name: "background_page" });
    },
  },
};
</script>
