<template>
  <div class="bgc">
    <div class="is-flex error" v-if="errorCode === '40390007'">
      <div>
        <WarningFilled />企业账号过期,请扫下面二维码联系阔知客服
      </div>
      <img class="img" src="../../assets/EduSohoCustomerServiceCode.png" alt="">
    </div>
    <div v-else-if="errorCode === '40390006' || errorCode === '40390004'" class="is-flex error">
      <div>
        <WarningFilled />您的企业未开通账号,请扫下面二维码联系阔知客服
      </div>
      <img class="img" src="../../assets/EduSohoCustomerServiceCode.png" alt="">
    </div>
    <div v-else-if="errorCode === '40390009'" class="error">
      <WarningFilled />企业微信授权登录失败
      <router-link :to="{name: 'home_login'}">
        <a-button class="btn-danger" type="danger">重新登录</a-button>
      </router-link>

    </div>
    <div v-else class="error">
      <WarningFilled />您的账号不可用,请联系管理员
      <router-link :to="{name: 'home_login'}">
        <a-button class="btn-danger" type="danger">重新登录</a-button>
      </router-link>

    </div>
  </div>

</template>

<script>
import { WarningFilled } from "@ant-design/icons-vue";

export default {
  name: "LoginError",
  components: {
    WarningFilled,
  },

  data() {
    return {
      errorCode: this.$route.query.errcode,
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>